<template>  
  <div>
    <Navigation/>
      <main class="main">     
        <div class="section-not-found container">
          <figure class="thankyou__picture">
            <img :src="imgUrl" alt="illustration-1" class="not-found__img">
          </figure>
          <h1 class="title">Page not Found</h1>
          <p class="paragraph">Sorry, the page you are looking for does not exist.</p>
          <a class="btn-back" @click="goToList">Back to home</a>                        
          </div>                                                          
        </main>
      <Footer/>
    </div>
</template>

<script>
/* eslint-disable global-require */

import Navigation from '@/modules/shop/components/experiences/Navigation'
import Footer from '@/modules/shop/components/experiences/Footer'
import store from '@/store/index'

export default {
  components: {      
    Navigation,           
    Footer,  
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods:{
    goToList(){      
      this.$router.push({ name:'results', query: {...this.queryParams} })
    }, 
  }
}
</script>

<style lang="scss" scoped>
.main{
  font-family: 'Century Gothic, Regular';
  margin: 5rem auto 2.5rem;/*centrado horizontal*/
  background-color: #fff;         
  color: #000;
}

.container{
  position: relative;
  width: 90%;
  max-width: 1200px;
  overflow: hidden;
  margin: 0 auto;    
    // height: 100vh;   
}

.section-not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.not-found__img{
    width: 100%;
    display: block; /*Quita el margen inferior por defecto de las imagenes y lo vuelve un elemento de tipo bloque*/
    max-width: 400px; /*que mida el 100% pero que no rebase los 400px*/
    margin: 0 auto; /*Cuando la imagen se centra*/
}
.title{
    color: #000;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 2rem 0;
}
.paragraph{
    text-align: center;
    color: #000;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 2rem;
}

.btn-back{
  text-decoration: none;
  color: #fff;
  background-color: #0068b4;
  padding: 1rem 3rem;
  border-radius: 6px;
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}


</style>
